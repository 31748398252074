<template>
  <div class="background">
    <div class="panel">
      <EatOrYeetPrivacy v-if="this.$route.params.app === 'eatoryeet'" />
      <GoGoPlanePrivacy v-else-if="this.$route.params.app === 'gogoplane'" />
      <GenericPrivacy v-else />
    </div>
    <div class="footer">
      <a href="mailto: contact@munmungames.com" class="TextContact">Contact</a>
      <a href="/" class="TextContact">Home</a>
    </div>
  </div>
</template>

<script>
import EatOrYeetPrivacy from "./Projects/EatOrYeetPrivacy.vue";
import GoGoPlanePrivacy from "./Projects/GoGoPlane.vue";
import GenericPrivacy from "./Projects/GenericPrivacy.vue";

export default {
  components: { EatOrYeetPrivacy, GoGoPlanePrivacy, GenericPrivacy }
};
</script>

<style scoped>
.background {
  width: 100%;
  height: 100%;
  background: url("../../assets/Images/stars-in-night-sky.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

@media screen and (min-width: 600px) {
  .panel {
    margin: 5% auto auto auto;
    width: 50%;
    height: 80%;
    flex-direction: column;
    background: rgb(255, 255, 255, 0.3);
    overflow-y: scroll;
    color: antiquewhite;
  }
}

@media screen and (max-width: 600px) {
  .panel {
    margin: 10% auto auto auto;
    width: 80%;
    height: 80%;
    flex-direction: column;
    background: rgb(255, 255, 255, 0.3);
    overflow-y: scroll;
    color: antiquewhite;
  }
}

.Text {
  font-family: "DKBrushCrush";
  font-size: 1.5em;
  height: 100px;
  margin: auto;
  color: white;
  text-align: center;
}

.TextContact {
  margin-bottom: 3%;
  margin-left: 1em;
  margin-right: 1em;
  color: white;
  text-decoration: underline;
}

.footer {
  display: flex;
  flex-direction: row;
  margin: 0;
  justify-content: center;
}
</style>
