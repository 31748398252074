<template>
  <div class="Header">
    <div class="Logo" @click="GoHome()"  v-if="GetScreenWidth()">
      <button class="Section">
          <p class="SectionTextBig"> OSWALD PFEIFFER <span class="Bolder"> | PORTFOLIO</span></p>
      </button>
    </div>
    <div class="Sections">
      <button class="Section" @click="GoToSection('SectionGames')">
        <p class="SectionText">GAMES</p>
      </button>
      <button class="Section" @click="GoToSection('SectionTeam')">
        <p class="SectionText">BIO</p>
      </button>
      <button class="Section" @click="GoToSection('SectionContact')">
        <p class="SectionText">CONTACT</p>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    GoHome () {
      const container = document.getElementById('Scroller');
      container.scrollTop = 0
    },
    GoToSection (sectionName) {
      const container = document.getElementById('Scroller');
      const section = document.getElementById(sectionName);
      const containerPosition = container.getBoundingClientRect().top;
      const offset = (section.offsetTop - 100) - containerPosition;
      container.scroll({
        top: offset,
        behavior: "smooth"
      });
    },
    GetScreenWidth () {
      return window.screen.width > 600;
    }
  }
}
</script>

<style scoped>
.Header {
  width: 100%;
  height: 4em;
  background: rgba(0,0,0,0.8);
  position: fixed;
  display: flex;
  flex-direction: row;
  z-index: 500;
}

.Logo {
  height: 100%;
  /*
  background: url("../assets/Images/MunMunGames_Simple.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  */
  width: 50%;
  pointer-events: auto;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
.Logo {
  height: 100%;
    /*
  background: url("../assets/Images/MunMunGames_Simple.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  */
  width: 30%;
}
}

.Sections {
  width: 50%;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-evenly;
}
@media screen and (max-width: 600px) {
.Sections {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 100%;
    justify-content: space-evenly;
}
}

.Section {
  height: 100%;
  width: auto;
  display: flex;
  pointer-events: auto;
  cursor: pointer;
}

.SectionText {
  margin: auto;
  width: auto;
  height: auto;
  color: white;
  font-size: 1.2em;
  font-family: 'Fredoka', sans-serif;
}

.SectionTextBig {
  margin: auto;
  margin-left: 20px;
  width: auto;
  height: auto;
  color: white;
  font-size: 2em;
  font-family: 'Fredoka', sans-serif;
}

.Bolder {
  font-weight: 300;
}
</style>