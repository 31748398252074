<template>
  <div class="TeamMember">
    <div class="Avatar">
      <img class="AvatarImage" :src="require('../assets/Images/' + preview)" />
    </div>
    <div class="Name">
      <p class="NameText">{{name}}</p>
    </div>
    <div class="Description">
      <p class="DescriptionText">{{role}}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    preview: {default: ''},
    name: {default: ''},
    role: {default: ''}
  }
}
</script>

<style scoped>
.TeamMember {
  width: 160px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.5em;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  position: relative;
}

.Avatar {
    width: 100%;
  height: auto;
}

.AvatarImage {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 100%;
  filter: drop-shadow(0px 2px 3px rgba(0,0,0, 0.2));
}

.Name {
  margin-top: 1em;
}

.NameText {
  font-family: 'Fredoka', sans-serif;
  font-size: 1.2em;
}

.Description {
  margin-top: 0.1em;
}

.DescriptionText {
  font-family: 'Fredoka', sans-serif;
  font-size: 1em;
  font-style: italic;
}
</style>