<template>
  <div class="Home" id="Scroller">
    <Header />
    <div class="Banner">
      <video 
      :src="GetVideoSource()" 
      class="Video" 
      autoplay="true"
			muted="muted"
			loop
      playsinline
      >
      </video>
      <div class="BlackOverlay"/>
      <!--<div class="Logo"></div>-->
    </div>
    <div class="Content">
      <Games />
    </div>
  </div>
</template>

<script>
import Header from './Header.vue'
import Games from './Games.vue'

export default {
  components: {
    Header,
    Games
  },
  methods: {
    GetVideoSource() {
      return require('@/assets/BG03.mp4');
    }
  }
};
</script>

<style scoped>
.Home {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.Banner {
  width: 100%;
  height: 50%;
  background: black;
  background: url("../assets/BG03.mp4");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  filter: drop-shadow(2px 2px 1px rgba(0,0,0, 0.8));
  position: relative;
}

.Video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.BlackOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.7;
}

.Logo {
  width: 100%;
  height: 100%;
  background: url("../assets/Images/MunMunGames_Simple.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.Content {
  background: white;
  width: 100%;
  height: auto;
}

@media screen and (min-width: 600px) {
  .panel {
    margin: 5% auto auto auto;
    width: 50%;
    height: 50%;
    flex-direction: column;
  }
}

@media screen and (max-width: 600px) {
  .panel {
    margin: 10% auto auto auto;
    width: 80%;
    height: 50%;
    flex-direction: column;
  }
}


.Text {
  font-family: "DKBrushCrush";
  font-size: 1.5em;
  height: 100px;
  margin: auto;
  color: white;
  text-align: center;
}

.TextContact {
  margin-bottom: 3%;
  color: white;
  text-decoration: underline;
}
</style>
