import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Privacy from "../views/Privacy/Privacy.vue";
import PrivacyVoodoo from "../views/Privacy/PrivacyVoodoo.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/privacypolicy/:app",
    name: "privacy",
    component: Privacy
  },
  {
    path: "/voodooprivacypolicy/:app",
    name: "privacyvoodoo",
    component: PrivacyVoodoo
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
