<template>
  <div class="Games" id="Scroller">
    <div class="Title"  id="SectionGames">
      <p class="TitleText">MY GAMES</p>
    </div>
    <div class="GamesList">
      <Game :preview="'RocketLauncher.jpg'" :name="'Precision Striker'" :company="'VOODOO (internal studio)'" 
      :iosURL="'https://apps.apple.com/lv/app/precision-striker/id6444911812'"
      :androidURL="'https://play.google.com/store/apps/details?id=games.instantclick.precisionstriker&hl=en_US'"/>

      <Game :preview="'MergeRaid.jpg'" :name="'Merge and Raid'" :company="'VOODOO (internal studio)'"
      :iosURL="'https://apps.apple.com/lv/app/merge-and-raid/id6472054477'"
      :androidURL="'https://play.google.com/store/apps/details?id=games.instantclick.mergeandraid&hl=en_US'"/>

      <Game :preview="'SupplyRush.jpg'" :name="'Supply Rush'" :company="'VOODOO (internal studio)'"
      :iosURL="'https://apps.apple.com/lv/app/supply-rush/id6477954391'"
      :androidURL="'https://play.google.com/store/apps/details?id=games.instantclick.supplyrush'"/>

      <Game :preview="'Icon.jpg'" :name="'Domino Surfer'" :company="'VOODOO (internal studio)'"
      :iosURL="'https://apps.apple.com/lv/app/domino-surfer/id1635577719'"
      :androidURL="'https://play.google.com/store/apps/details?id=games.instantclick.dominosurfer'"/>

      <Game :preview="'FightAnArmy.jpg'" :name="'Fight an Army'" :company="'VOODOO (internal studio)'"
      :iosURL="'https://apps.apple.com/lv/app/fight-an-army/id6444777490'"
      :androidURL="'https://play.google.com/store/apps/details?id=games.instantclick.fightanarmy'"/>

      <Game :preview="'Icon2.png'" :name="'Shots for All'" :company="'VOODOO (internal studio)'"
      :iosURL="'https://apps.apple.com/lv/app/shots-for-all/id6444708871'"
      :androidURL="'https://play.google.com/store/apps/details?id=games.instantclick.shotsforall'"/>

      <Game :preview="'GameIcon.png'" :name="'Magnet Crowd'" :company="'VOODOO (internal studio)'"
      :iosURL="'https://apps.apple.com/lv/app/magnet-crowd/id1636380263'"
      :androidURL="'https://play.google.com/store/apps/details?id=games.instantclick.magnetcrowd'"/>
      
      <Game :preview="'SwingDoll.jpg'" :name="'Swing Doll'" :company="'VOODOO (internal studio)'"
      :iosURL="'https://apps.apple.com/lv/app/swing-doll/id1642205898'"
      :androidURL="'https://play.google.com/store/apps/details?id=games.instantclick.swingdoll'"/>

      <Game :preview="'RunningWheels.jpg'" :name="'Running Wheels'" :company="'VOODOO (internal studio)'"
      :iosURL="'https://apps.apple.com/lv/app/running-wheels/id6443829899'"
      :androidURL="'https://play.google.com/store/apps/details?id=games.instantclick.runningwheels'"/>

      <Game :preview="'MatchSoldier.jpg'" :name="'Match Soldier'" :company="'VOODOO (internal studio)'"
      :iosURL="'https://apps.apple.com/lv/app/match-soldier/id6476206729'"
      :androidURL="'https://play.google.com/store/apps/details?id=games.instantclick.matchsoldier'"/>

      <Game :preview="'icon_Best_1024 1.jpg'" :name="'Kitchen Run'" :company="'VOODOO (internal studio)'"
      :iosURL="'https://apps.apple.com/lv/app/kitchen-run/id1630923776'"
      :androidURL="'https://play.google.com/store/apps/details?id=games.instantclick.kitchenrun'"/>

      <Game :preview="'Icon_rainballs.png'" :name="'Rain Balls Color'" :company="'VOODOO (freelancing)'" 
      :androidURL="'https://play.google.com/store/apps/details?id=com.OswaldPfeiffer.RainBallsColor'"/>

      <Game :preview="'Icon_eyes.png'" :name="'Slime Strip 3D'" :company="'VOODOO (freelancing)'" 
      :androidURL="'https://play.google.com/store/apps/details?id=com.OswaldPfeiffer.SlimeStrip3D'"/>

      <Game :preview="'ageofio.png'" :name="'AgeOf.io'" :company="'VOODOO (freelancing)'" 
      :androidURL="'https://play.google.com/store/apps/details?id=com.OswaldPfeiffer.AgeOfIO'"/>

      <Game :preview="'drawgrind.png'" :name="'Draw grind'" :company="'VOODOO (freelancing)'" 
      :iosURL="'https://youtube.com/shorts/ryspUxuWjXc?feature=share'"/>

      <Game :preview="'Cover7_small.png'" :name="'City Escape Runner'" :company="'INDIE'" 
      :androidURL="'https://play.google.com/store/apps/details?id=com.MunMunGames.CityEscapeRunner'"/>

      <Game :preview="'MagneticGolf_512.jpg'" :name="'Magnetic Golf'" :company="'INDIE'" 
      :androidURL="'https://play.google.com/store/apps/details?id=com.MunMunGames.MagneticGolf'"/>

      <Game :preview="'gogoplane.jpg'" :name="'Go Go Plane'" :company="'INDIE'" 
      :androidURL="'https://play.google.com/store/apps/details?id=com.MunMunGames.GoGoPlane'"/>

      <Game :preview="'moonlightChannel.jpg'" :name="'Moonlight Channel'" :company="'INDIE'" 
      :itchURL="'https://munmungames.itch.io/moonlight-channel'"/>

      <Game :preview="'lightfromastar.jpg'" :name="'Light From a Star'" :company="'INDIE'" 
      :itchURL="'https://munmungames.itch.io/light-from-a-star'"/>

      <Game :preview="'eatyeet.jpg'" :name="'Eat or Yeet'" :company="'INDIE'" 
      :androidURL="'https://play.google.com/store/apps/details?id=com.MunMunGames.EatOrYeet'"/>
    </div>
    <hr>
    <div class="TitleTeam" id="SectionTeam">
      <p class="TitleText">BIO</p>
    </div>
    <div class="TeamList">
      <TeamMember :preview="'OP.jpg'" :name="'Oswald Pfeiffer'" :role="'Game Developer'"/>
      <div class="SocialButtons">
        <button class="ButtonObj" @click="OpenLinkedin()">
          <img class="ButtonImg" src="LinkedinButton.png"/>
        </button>

        <a class="ButtonObj" @click="DownloadResume()" href="/CV_OswaldPfeiffer_2024_english.pdf" download>
          <img class="ButtonImg" src="Button_Resume.png"/>
        </a>
      </div>
      <div class="Bio">
        <p class="TitleBio">
      🎮 Unity developer 
        </p>
        <p class="BlockBio"> More than 10 years of experience with Unity / C#. </p>
        <p class="TitleBio">
      📝 Lead developer (current position)
        </p>
        <p class="BlockBio">Good communication and management skills. <br>
Focused on writing clean, readable, robust and perfomant code in short delays. </p>
                <p class="TitleBio">
      👾 Enthusiast and creative 
        </p>
        <p class="BlockBio"> Game design skills and strong knowledge of KPI driven mobile game development </p>
                <p class="TitleBio">
      👨‍💻 Experienced with different game oriented technologies
        </p>
        <p class="BlockBio"> Unreal Engine, 3D modelling with Maya, Photoshop, creative coding in JS/Vue.js/Three.js.</p>
                <p class="TitleBio">
🎓 Master's degree in architecture
        </p>
        <p class="BlockBio"> (ENSA Paris Malaquais) Graduate project focused on the development of a VR multiplayer sandbox game for architectural conception. </p>
      </div>
    </div>
    <div class="ContactBG" id="SectionContact">
      <img class="Rocket" :src="require('@/assets/Images/rocket-heading-for-the-moon-5272600-4411742.png')" /> 
      <div class="Block">
        <p class="TitleTextWhite">CONTACT ME</p>
        <a href="mailto:oswald.pfeiffer@gmail.com" class="TextContact">oswald.pfeiffer@gmail.com</a>
      </div>
    </div>
  </div>
</template>

<script>
import Game from './Game.vue'
import TeamMember from './TeamMember.vue'

export default {
  components: {
    Game,
    TeamMember
  },
  methods: {
    OpenLinkedin () {
      window.open("https://www.linkedin.com/in/oswald-pfeiffer-385b56b7/", '_blank');
    }
  }
}
</script>

<style scoped>
.Games {
  display: flex;
  flex-direction: column;
}

.Title {
  margin-top: 2em;
  width: 100%;
  height: 3em;
}

.TitleTeam {
  width: 100%;
  height: 3em;
}

.TitleText {
  margin: auto;
  color: black;
  font-family: 'Fredoka', sans-serif;
  font-size: 2em;
  font-weight: 600;
}

.TitleTextWhite {
  margin: auto;
  color: white;
  font-family: 'Fredoka', sans-serif;
  font-size: 2em;
  font-weight: 600;
  margin-bottom: 3em;
  filter: drop-shadow(0px 2px 3px rgba(0,0,0, 0.8));
}

.SocialButtons {
  width: 100%;
  height: 70px;
  margin-bottom: 1.8em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  filter: drop-shadow(0px 2px 3px rgba(0,0,0, 0.2));
}

@media screen and (max-width: 600px) {
.SocialButtons {
  width: 100%;
  height: auto;
  margin-bottom: 1.8em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  filter: drop-shadow(0px 2px 3px rgba(0,0,0, 0.2));
}
}


.ButtonObj {
  cursor: pointer;
  width: auto;
  height: 70px;
  margin-top: 0%;
  margin-bottom: 0%;
  padding: 0%;
}

.ButtonImg {
  width: auto;
  height: 100%;
  padding: 0%;
}

.Bio {
  width: 50%;
  margin: auto;
}

@media screen and (max-width: 600px) {
.Bio {
  width: 90%;
  margin: auto;
}
}

@media screen and (max-width: 600px) {
.SocialButtons {
  width: 100%;
  margin: auto;
  margin-bottom: 2em;
}
}

.TitleBio {
 font-size: 1em;
   margin-bottom: 0.5em;
   text-align: left;
   font-weight: 800;
}

.BlockBio {
  margin-bottom: 1em;
     text-align: left;
     margin-left: 3em;
}

.GamesList {
  margin: auto;
  width: 70%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 3em;
  align-content: flex-start;
  justify-content: center;
}

.TeamList {
  margin: auto;
  width: 70%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

@media screen and (max-width: 600px) {
.TeamList {
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
}

.ContactBG {
  margin-top: 2em;
  width: 100%;
  height: 300px;
  background: url("../assets/Images/stars-in-night-sky.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  filter: drop-shadow(2px 2px 1px rgba(0,0,0, 0.8));
  position: relative;
}

hr {
  width: 50%;
  margin-top: 2em;
  margin-bottom: 4em;
}

.TextContact {
  margin: auto;
  font-family: 'Fredoka', sans-serif;
  font-size: 1.2em;
  font-weight: 600;
  color: white;
  text-decoration: underline;
  filter: drop-shadow(0px 2px 3px rgba(0,0,0, 0.8));
}

.Block {
  z-index: 200;
}

.Rocket {
  position: absolute;
  width: 100%;
  height: 90%;
  object-fit: contain;
}
</style>