<template>
  <div class="Game">
    <div class="Preview">
      <img class="PreviewImage" :src="require('../assets/Images/' + preview)"/>
    </div>
    <div class="Title">
      <p class="TitleText">{{name}}</p>
      <p class="TitleText2">{{company}}</p>
    </div>
    <button class="ButtonClick" v-if="androidURL !== ''" @click="OpenUrl(androidURL)" >
      <img class="ButtonImage" :src="require('@/assets/Images/Gplay.png')"/>
    </button>
    <button class="ButtonClick" v-if="iosURL !== ''" @click="OpenUrl(iosURL)">
      <img class="ButtonImage" :src="require('@/assets/Images/AppStore.png')"/>
    </button>
    <button class="ButtonClick" v-if="itchURL !== ''" @click="OpenUrl(itchURL)">
      <img class="ButtonImage" :src="require('@/assets/Images/badge-color.png')"/>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    preview: {default: ''},
    name: {default: ''},
    company: {default: ''},
    androidURL: {default: ''},
    iosURL: {default: ''},
    itchURL: {default: ''}
  },
  methods: {
    OpenUrl (url) {
      window.open(url, '_blank');
    }
  }
}
</script>

<style scoped>
.Game {
  width: 250px;
  height: auto;
  margin-bottom: 0em;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  position: relative;
}

.Preview {
  width: 100%;
  height: auto;
}

.PreviewImage {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 15px;
  filter: drop-shadow(0px 2px 3px rgba(0,0,0, 0.2));
}

.Title {
  margin-top: 0.5em;
}

.TitleText {
  font-family: 'Fredoka', sans-serif;
  font-size: 1.2em;
}

.TitleText2 {
  font-family: 'Fredoka', sans-serif;
  font-weight: 100;
  font-size: 1em;
}


.ButtonClick {
  pointer-events: auto;
  cursor: pointer;
  margin-top: 1em;
  margin-left: auto;
  margin-right: auto;
  width: 75%;
  height: auto;
  position: relative;
}

.ButtonImage {
  width: 100%;
  height: auto;
  filter: drop-shadow(0px 2px 3px rgba(0,0,0, 0.2));
}
</style>